<template src="./cancelled.html"></template>

<script>
export default {
  data() {
    return {
      windowWidth: 0,
      showWebTable: true,
      canceled: [],
      toRerenderWebTable: 0,
      toRerenderMobileTable: 0,
      numPages: 0,
      currentx: 1,
      pagination: false,
      searchedWord: "",
    };
  },
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted: function () {
    const vm = this;
    vm.init();
  },
  watch: {
    currentx: function () {
      const vm = this;
      vm.changePagination();
    },
  },
  methods: {
    init() {
      const vm = this;
      vm.getPerformed();
    },
    handleResize() {
      const vm = this;
      vm.windowWidth = window.innerWidth;
      if (vm.windowWidth <= 1025) {
        vm.showWebTable = false;
      } else {
        vm.showWebTable = true;
      }
    },
    handleSearch(searching) {
      const vm = this;
      vm.searchedWord = searching;
      vm.currentx = 1;
      setTimeout(() => {
        vm.init(); 
      }, 1000); 
    },
    getPerformed() {
      const vm = this;
      vm.$vs.loading();
      let page = 0;
      if (vm.currentx > 0) {
        if (vm.currentx == 1) {
          page = 0;
        } else {
          page = vm.currentx - 1;
        }
      }
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "appointments/history-cancelled?page=" +
            page +
            "&search=" +
            vm.searchedWord +
            "&size=10&sort=id,desc",
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.canceled = response.data.content;
            vm.numPages = response.data.totalPages;
            vm.pagination = vm.numPages > 1;
            for(const c in vm.canceled){
              vm.canceled[c].doctorName = vm.canceled[c].doctor.name;
              vm.canceled[c].specialtyName = vm.canceled[c].specialty.name;
            }
          }
          vm.$vs.loading.close();
        })
        .catch(function () {
          vm.$vs.loading.close();
        });
    },
    changePagination() {
      const vm = this;
      vm.getPerformed();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateList() {
      const vm = this;
      vm.searchedWord = "";
      vm.currentx = 1;
      if (vm.toRerenderWebTable === 0) vm.toRerenderWebTable += 1;
      else vm.toRerenderWebTable -= 1;
      if (vm.toRerenderMobileTable === 0) vm.toRerenderMobileTable += 1;
      else vm.toRerenderMobileTable -= 1;
      vm.init();
    },
  },
};
</script>

<style lang="scss" src="./cancelled.scss">
</style>